import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import { withTrans } from "../withTrans"
import parse, { domToReact } from "html-react-parser"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!, $id: ID) {
    detailsItem: gcms {
      pressPage(locales: $locale, where: { id: $id }) {
        id
        title
        subtitle
        longDescription {
          html
        }
        links {
          html
        }
        explore {
          html
        }
      }
    }
  }
`
class PressDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pressDetailsData: this.props.data.detailsItem.pressPage,
    }
  }
  render() {
    const { t } = this.props
    const options = {
        replace: ({ attribs, name, children }) => {
          if (name === "a") {
            return (
              <a
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
                href={attribs.href}
              >
                {domToReact(children, options)}
              </a>
            )
          }
        },
      }
    return (
      <Layout>
        <div>
          <div className="font-futurastd_book text-center lg:grid lg:grid-rows-1 lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
            <div className="text-center inline-block bg-white">
              <span className="text-2xl">&lt;</span>
              <span className="tracking-title-3 text-base ml-3 mr-2 uppercase">
                {this.state.pressDetailsData.title}
              </span>
              <span className="text-2xl">&gt;</span>
            </div>
          </div>
          <div className="mx-3 lg:mx-7">
            <div className="grid grid-col-1 md:grid-cols-5 mx-7 gap-4">
              <div className="col-span-1">
                <p className="font-futurastd_heavy uppercase text-lg">
                  {this.state.pressDetailsData.subtitle}
                </p>
              </div>
              <div className="col-span-1 md:col-span-3 font-futurastd_book text-xs order-last md:order-none">
                {parse(this.state.pressDetailsData.longDescription.html, options)}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withTrans(PressDetails)
